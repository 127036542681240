<template>
  <div class="reservingSessionBox white mt-6">
    <IndicatorSessionReserve :step="reservingWindow" @changeStep="changeStep"/>
    <v-window v-model="reservingWindow" touchless>
      <v-window-item :value="1" class="">
        <SelectCycle @changeStep="changeStep"/>
      </v-window-item>
      <v-window-item :value="2">
        <SelectTime @changeStep="changeStep"/>
      </v-window-item>
      <v-window-item :value="3">
        <div class="successMassage text-center py-10 px-lg-15">
          <div class="font-weight-bold">
            {{ $t('accept_request.title') }}
          </div>
          <div class="px-15 mt-3 descSuccessMessage">
            <template v-if="profile.leadUser">
              {{ $t('accept_request.newUser') }}
            </template>
            <template v-else>
              {{ $t('accept_request.oldUser') }}
            </template>
          </div>
          <img class="mt-10" src="../../assets/image/svg/session/assign.svg" alt="">
          <div>
            <v-btn color="#47c8bd" class="confirmBtn" @click="confirm">
              {{ $t('confirm') }}
            </v-btn>
          </div>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
/*eslint-disable*/
import IndicatorSessionReserve from "@/components/Sessions/indicatorSessionReserve";
import SelectCycle from "@/components/Sessions/SelectCycle";
import SelectTime from "@/components/Sessions/SelectTime";
import {mapGetters} from "vuex";

export default {
  components: {SelectTime, SelectCycle, IndicatorSessionReserve},
  data() {
    return {
      reservingWindow: 1
    }
  },
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
    })
  },
  methods: {
    changeStep(step) {
      this.reservingWindow = step
    },
    confirm() {
      this.$router.push({name: 'sessions'})
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px ) and (max-width: 600px) {
  .reservingSessionBox {
    width: 100% !important;
    padding: 10px !important;
  }
}

.reservingSessionBox {
  height: 92%;
  width: 90%;

  margin: 0px auto;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 15px 15px 50px 15px;
}

.descSuccessMessage {
  font-size: 12px;
}

.confirmBtn {
  width: 173px;
  height: 40px !important;
  margin: 9px 1px 21px 14px;
  padding: 0 19px 0 0;
  border-radius: 9px;
  box-shadow: 0 2px 3px 0 rgba(175, 175, 175, 0.46);
  background-color: #47c8bd;
  color: white;
}


</style>