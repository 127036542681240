<template>
  <div class="pt-5">
<!--    <div class="tabsHeader d-flex  justify-space-between  mt-5" v-if="$vuetify.breakpoint.mdAndUp">-->
<!--      <div class="mx-4 tabHeader" :class="typePackage == 'packages' && 'activeTab'" @click="setType('packages')">-->
<!--        جلسه-->
<!--      </div>-->
<!--      <div class="mx-4 tabHeader" :class="typePackage == 'questions' && 'activeTab'" @click="setType('questions')">-->
<!--        سوال-->
<!--      </div>-->
<!--      <div class="mx-4 tabHeader" :class="typePackage == 'tests' && 'activeTab'" @click="setType('tests')">-->
<!--        تست-->
<!--      </div>-->
<!--      <v-spacer></v-spacer>-->
<!--    </div>-->
<!--    <div clapackss="tabsHeaderMob d-flex" v-else>-->
<!--      <div class="mx-2 d-flex justify-center align-center">-->
<!--        فیلتر-->
<!--      </div>-->
<!--      <div class=" flex-grow-1">-->
<!--        <select style="width: 100%;background: white;padding: 5px;border-radius: 13px;height: 100%" v-model="typePackage">-->
<!--          <option value="packages">-->
<!--            جلسه-->
<!--          </option>-->
<!--          <option value="questions">-->
<!--            سوال-->
<!--          </option>-->
<!--          <option value="tests">-->
<!--            تست-->
<!--          </option>-->
<!--        </select>-->
<!--      </div>-->
<!--    </div>-->
    <Packages type="session"/>

<!--    <v-window v-model="typePackage" touchless>-->
<!--      <v-window-item value="packages" class="mt-5">-->
<!--      </v-window-item>-->
<!--      <v-window-item value="questions" class="mt-5">-->
<!--        <Packages type="question"/>-->
<!--      </v-window-item>-->
<!--      <v-window-item value="tests" class="mt-5">-->
<!--        <Packages type="tests"/>-->
<!--      </v-window-item>-->
<!--    </v-window>-->
  </div>
</template>

<script>
import Packages from "@/components/Packages/Packages";

export default {
  components: {Packages},
  data() {
    return {
      typePackage: 'packages',
    }
  },
  methods: {
    setType(val) {
      this.typePackage = val
    }
  }
}
</script>

<style scoped>
.tabsHeader {
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.tabsHeaderMob {
  background: #6d6d6d;
  padding: 3px;
  font-size: 13px;
  color: white;
  border-radius: 13px;
  margin-top: 20px;
  height: 40px ;
}

.tabsHeader {
  color: #6d6d6d;
  font-size: 15px;
}

.tabHeader {
  padding: 0px 10px 5px 10px;
  border-bottom: 1px solid white;
  cursor: pointer;
}

.activeTab {
  color: black;
  border-bottom: 1px solid black;
}
</style>