<template>
  <div class="mb-10  fill-height">
    <!--    <SegmentController :segments="segments"/>-->
    <!--    <v-window class="windowArea" v-model="segments.selected" touchless>-->
    <!--      <v-window-item value="packages">-->
    <div class="windowArea">
      <PackagesSection/>
    </div>
    <!--      </v-window-item>-->
    <!--      <v-window-item value="reserve">-->
    <!--        <ReserveSession @changeStep="changeStep($event)"/>-->
    <!--      </v-window-item>-->
    <!--    </v-window>-->
  </div>
</template>

<script>
/*eslint-disable*/
import SegmentController from "@/components/Main/SegmentController";
import PackagesSection from "@/components/Packages/PackagesSection";
import ReserveSession from "@/components/Sessions/ReserveSession";

export default {
  components: {ReserveSession, PackagesSection, SegmentController},
  data() {
    return {
      segments: {
        items: [
          {
            title: 'رزرو وقت',
            key: 'reserve',
          },
          {
            title: 'بسته‌ها',
            key: 'packages',
          },
        ],
        selected: "reserve",
      }
    }
  },
  computed: {},
  methods: {
    changeStep(val) {
      this.segments.selected = val
    }
  }
}
</script>

<style scoped>
.windowArea {
  width: 88%;
  margin: 0px auto;
}
</style>