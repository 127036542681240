<template>
  <div class="mt-5" id="select_time_by_calendar">
    <div class="titleSection">
      {{ $t('select_time.title') }}
    </div>
    <div class="subtitleSection">
      {{ $t('select_time.description') }}
    </div>
    <div class="calendarArea mt-5">
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <div class="monthLabel">
          <span v-for="(item , i ) in months" :key="i">
            <template v-if="i != 0">
              /
            </template>
            {{ item }}
          </span>
        </div>
        <div class="calendar d-flex flex-row-reverse mt-5">
          <div class="arrowAndHour justify-start">
            <div class="buttons d-flex justify-end " :class="!$vuetify.rtl && 'flex-row-reverse'">
              <div class="nextButton mx-1" id="nextMonth" @click="prepareDays('add',7)">
                <img src="../../assets/image/svg/session/arrow_white.png" width="90%" alt="">
              </div>
              <div class="prevBtn mx-1" id="prevMonth" @click="prepareDays('subtract',7)">
                <img src="../../assets/image/svg/session/arrow_white.png" width="90%" alt="">
              </div>
            </div>
            <div class="hourPeriods mt-2">
              <div class="hourItem ">
                <div>
                  8-12
                </div>
                <div>
                  <img src="../../assets/image/svg/session/morning.svg" alt="">
                </div>
              </div>
              <div class="hourItem">
                <div>
                  12-16
                </div>
                <div>
                  <img src="../../assets/image/svg/session/Noon.svg" alt="">
                </div>
              </div>
              <div class="hourItem">
                <div>
                  16-20
                </div>
                <div>
                  <img src="../../assets/image/svg/session/afternoon.svg" alt="">
                </div>
              </div>
              <div class="hourItem">
                <div>
                  20-24
                </div>
                <div>
                  <img src="../../assets/image/svg/session/night.svg" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="days  flex-grow-1">
            <v-fade-transition>
              <div class="d-flex justify-space-between flex-row-reverse dayColumn  fill-height "
                   v-if="days">
                <div v-for="(day , i) in days" :key="i" class="day  fill-height flex-grow-1"
                     style="background: #fcfcfc">
                  <div class="dayCount en_num" :id="`${dayCountM(day)}_label`">
                    {{ day | dayCount }}
                  </div>
                  <div class="dayLabel">
                    {{ day | dayLable }}
                  </div>
                  <div class="daysCheckBox d-flex flex-column  mt-3 justify-space-around  fill-height text-center  ">
                    <div class="checkBox  text-center" v-for="n in 4" :key="n" @click="selectingDate(day , n)"
                         :id="`${dayCountM(day)}_${n}`">
                      <v-fade-transition>
                        <div class="activeCheckbox" :id="`${dayCountM(day)}_${n}_active_state`"
                             v-if="isTimeSelected(day , n )"></div>
                      </v-fade-transition>
                    </div>
                  </div>
                </div>
              </div>
            </v-fade-transition>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="monthLabel text-left ">
          <div class="monthLabel" :class="!$vuetify.rtl && 'text-right'">
          <span v-for="(item , i ) in months" :key="i">
            <template v-if="i != 0">
              /
            </template>
            {{ item }}
          </span>
          </div>
        </div>
        <div class="headerCalendar d-flex ">
          <div class="flex-grow-1 ml-2 hourPeriods">
            <div class="hourItem ">
              <div>
                <img src="../../assets/image/svg/session/morning.svg" alt="">
              </div>
              <div>
                8-12
              </div>
            </div>
            <div class="hourItem">
              <div>
                <img src="../../assets/image/svg/session/Noon.svg" alt="">
              </div>
              <div>
                12-16
              </div>
            </div>
            <div class="hourItem">
              <div>
                <img src="../../assets/image/svg/session/afternoon.svg" alt="">
              </div>
              <div>
                16-20
              </div>
            </div>
            <div class="hourItem">
              <div>
                <img src="../../assets/image/svg/session/night.svg" alt="">
              </div>
              <div>
                20-24
              </div>
            </div>
          </div>
          <div class="buttons  d-flex justify-space-between" :class="!$vuetify.rtl && 'flex-row-reverse'">
            <div class="nextButton " @click="prepareDays('add',7)">
              <img src="../../assets/image/svg/session/arrow_white.png" width="90%" alt="">
            </div>
            <div class="prevBtn " @click="prepareDays('subtract',7)">
              <img src="../../assets/image/svg/session/arrow_white.png" width="90%" alt="">
            </div>
          </div>
        </div>
        <div class="days">
          <div class="dayRow mb-5 d-flex " v-for="(day , i) in days" :key="i">
            <div class="flex-grow-1 d-flex justify-space-between">
              <div v-for="n in 4" :key="n" class=" ml-3 flex-grow-1">
                <div class="checkBox " @click="selectingDate(day , n)">
                  <v-fade-transition>
                    <div class="activeCheckbox" v-if="isTimeSelected(day , n )"></div>
                  </v-fade-transition>
                </div>
              </div>
            </div>
            <div class="dayLabel en_num">
              {{ day | dayLable }}
              {{ day | dayCount }}

            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="text-center mt-4">
      <v-btn class="confirm" color="#46b1a1" @click="storeTime">
        {{ $t('select_time.continue') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import moment from "moment-jalaali";

export default {
  data() {
    return {
      days: [],
      months: [],
      today: new Date(),
      timePeriod: {
        1: {start: '08:00', end: '12:00'},
        2: {start: '12:00', end: '16:00'},
        3: {start: '16:00', end: '20:00'},
        4: {start: '20:00', end: '24:00'},
      },
      selected: [],
    }
  },
  mounted() {
    this.prepareDays()
  },
  methods: {
    isTimeSelected(date, timePeriod) {
      let target = this.selected.findIndex(item => item.date == date)
      if (target != -1) {
        if (this.selected[target].periods.findIndex(item => item == this.timePeriod[timePeriod]) != -1) {
          return true
        }
      }
      return false
    },
    selectingDate(date, timePeriod) {
      if (this.selected.findIndex(item => item.date == date) == -1) {
        this.selected.push({
          date: date,
          periods: [],
        })
      }
      let targetDate = this.selected.find(item => item.date == date)
      let targetTime = this.timePeriod[timePeriod]
      let index = targetDate.periods.findIndex(item => item == targetTime)
      if (index == -1) {
        targetDate.periods.push(targetTime)
      } else {
        targetDate.periods.splice(index)
      }
    },
    prepareDays(action = null, days = 0) {
      if (action != null && days != 0) {

        if (action == 'add') {
          this.today = moment(this.today).add({days: days})
        } else if (action == 'subtract') {
          this.today = moment(this.today).subtract({days: days})
        }
      }
      if (this.today) {
        this.days = []
        const date = moment(this.today).locale('fa');
        let dow = date.day() + 2;
        // console.log(dow, '/5555')
        if (dow == 8) dow = 1
        // else if (dow == 1) dow = 2
        // console.log(dow, '1212')
        let nextDays = 7 - dow
        let beforeDay = 7 - nextDays
        // console.log(dow, nextDays, beforeDay, '333333')
        if (beforeDay > 1) {
          for (var i = 1; i <= (beforeDay - 1); i++) {
            this.days.unshift(moment(this.today).subtract({days: i}).format('Y/M/D'))
          }
        }
        this.days.push(moment(this.today).format('Y/M/D'))
        // if (nextDays > 1) {
        for (var i = 1; i <= (nextDays); i++) {
          this.days.push(moment(this.today).add({days: i}).format('Y/M/D'))
        }
        // }
        // console.log(this.days)
        this.prepareMonthLabel()
      }

    },
    prepareMonthLabel() {
      this.months = []
      this.days.forEach((item) => {
        let temp = moment(item).format('MMMM').substr(0, 3)
        if (this.months.findIndex(item => item == temp) == -1) {
          this.months.push(temp)
        }
      })
      // console.log(this.months)
    },
    storeTime() {
      let finalTime = this.selected.filter(item => item.periods.length != 0)
      this.$emitEvent('setLoading', true)
      this.requesting('session', 'storeTome', '', {
        timebooks: finalTime
      }).then(() => {
        this.$emit('changeStep', 3)
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    dayCountM(date) {
      return moment(date).format('D')
    }
  },
  filters: {
    dayCount(val) {
      return moment(val).format('D')
    },
    dayLable(val) {
      return moment(val).format('ddddd').substr(0, 2).toUpperCase()
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .calendarArea {
    width: 100% !important;
    border: none !important;
  }

  .buttons {
    width: 65px !important;
  }

  .dayLabel {
    width: 65px !important;
    font-size: 12px;
  }

  .hourItem {
    flex-direction: column !important;
    line-height: 10px !important;
  }

  .hourPeriods {
    height: 50px !important;
    display: flex !important;
    justify-content: space-between;
  }
}

.dayColumn {
}

.day {
  font-size: 12px;
  /*padding-bottom: 0px;*/

}

.daysCheckBox {
  height: 160px;
}

.buttons {
  /*width: 100px;*/
  height: 40px;
}

.nextButton {
  background: #46b1a1;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  cursor: pointer;
}

.prevBtn {
  background: #46b1a1;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  transform: rotate(-180deg);
  cursor: pointer;
}

.titleSection {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.subtitleSection {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.calendarArea {
  border: 1px solid #dbdbdb;
  width: 75%;
  margin: 0px auto;
  padding: 18px;
  text-align: center;
  border-radius: 15px;
}

.hourItem {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.hourPeriods {
  padding: 11px;
  height: 160px;
}

.hourItem img {
  width: 20px;
  margin-right: 5px;
}

.checkBox {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: solid 1px #46b1a1;
  background-color: rgba(70, 177, 161, 0.06);
  padding: 1px;
  margin: 0px auto;
  cursor: pointer;
}

.activeCheckbox {
  background: #46b1a1;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.confirm {
  width: 173px;
  height: 38px;
  border-radius: 9px !important;
  box-shadow: 0 2px 3px 0 rgba(175, 175, 175, 0.46) !important;
  color: white;
}
</style>