<template>
  <div>
    <ModalSelectOrCreateCycle @selectCycle="selectCycle($event)" @closeModal="closeModal" :cycles-modal="cyclesModal"/>
    <div class="title_section mt-10">
      {{ $t('select_cycle.titleSection') }}
    </div>
    <div class="cycleInfo">
      <v-row class="">
        <v-col cols="6" lg="6">
          <div class="cycleData titleCycleBox" @click="openModalSelectOrCreateCycle">
            <div class="d-flex justify-space-between  fill-height"  :class="!$vuetify.rtl && 'flex-row-reverse'">
              <div>
                <img src="../../assets/image/svg/session/BackCycle.svg" alt="">
              </div>
              <div class="text-left white--text d-flex flex-column justify-space-between fill-height">
                <div class="titleCycle">
                  {{ $t('select_cycle.title') }}
                </div>
                <div class="titleCycleCustom" v-if="cycle">
                  {{ cycle.heading }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="6" lg="6">
          <div class="packageInfo ">
            <div class="titlePackage">
              <template v-if="selectedWallet.full_name != ''">
                {{ selectedWallet.full_name }}
              </template>
              <template v-else>
                {{ $t('select_cycle.package') }}
              </template>
            </div>
            <div class="duration">
              {{ $t('select_cycle.duration') }}
            </div>
            <div class="doctorName">
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="12">
          <div class="cycleData descCycleBox" @click="openModalSelectOrCreateCycle">
            <div class="d-flex justify-space-between   fill-height" :class="!$vuetify.rtl && 'flex-row-reverse'">
              <div>
                <img src="../../assets/image/svg/session/BackCycle.svg" alt="">
              </div>
              <div class="text-left white--text ">
                <div class="titleCycle">
                  {{ $t('select_cycle.description') }}
                </div>
                <div class="mt-1 descCycle" v-if="cycle">
                  {{ cycle.problem_description }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div>
      </div>
    </div>
    <div class="text-center pb-2">
      <v-btn text color="#46b1a1" @click="openModalSelectOrCreateCycle">
        {{ $t('select_cycle.selectCycle') }}
      </v-btn>
      <br>
      <v-btn class="confirm" color="#46b1a1" @click="confirm" id="select_cycle_btn">
        {{ $t('select_cycle.action') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ModalSelectOrCreateCycle from "@/components/Cycle/ModalSelectOrCreateCycle";

export default {
  components: {ModalSelectOrCreateCycle},
  data() {
    return {
      cycle: null,
      cyclesModal: {
        visibility: false
      },
    }
  },
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
      'selectedWallet': 'getSelectedSessionWalllet',
    })
  },
  mounted() {
    if (this.profile) {
      this.cycle = this.profile.cycles[0]
    }
    // this.cycle = this.profile.cycles[this.profile.cycles.length - 1]
  },
  methods: {
    openModalSelectOrCreateCycle() {
      this.cyclesModal.visibility = true
    },
    selectCycle(cycle) {
      this.cycle = cycle
      this.closeModal()
    },
    closeModal() {
      this.cyclesModal.visibility = false
    },
    confirm() {
      if (this.cycle) {
        this.$emitEvent('setLoading', true)
        this.requesting('session', 'sendSessionRequest', null, {
          cycle_id: this.cycle.id,
          session_count: 1
        }).then(() => {
          this.$emit('changeStep', 2)
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })
      } else {
        this.$emitEvent('notify', {title: this.$t('select_cycle.notify.selectCycle'), color: 'red'})
      }

    }
  }
}
</script>

<style scoped>
@media (min-width: 200px ) and (max-width: 600px) {
  .cycleInfo {
    width: 100% !important;
  }
}

.title_section {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.cycleInfo {
  width: 60%;
  margin: 20px auto;
}

.packageInfo {
  height: 100%;
  width: 100%;
  border: solid 1px #78a5ff;
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s;
}

.titlePackage {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: left;
  color: #4576d8;
}

.duration {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #4576d8;
}

.doctorName {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #4576d8;
}

.cycleData {
  background: #6d5aac;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
}

.titleCycleBox {
  min-height: 100px;
  transition: all 0.3s;
}

.descCycleBox {
  height: 150px;
}

.titleCycle {
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  font-weight: bolder;
}

.confirm {
  width: 173px;
  height: 38px;
  border-radius: 9px !important;
  box-shadow: 0 2px 3px 0 rgba(175, 175, 175, 0.46) !important;
  color: white;
}


</style>