<template>
  <div style="" v-if="packages">
    <v-row>
      <v-col cols="6" md="4" lg="3" v-for="(item , i ) in packages" :key="i">
        <PackageBox :package-info="item"/>
      </v-col>
    </v-row>
    <v-pagination v-model="pagination.page" v-if="packages.length != 0" :length="pagination.total"></v-pagination>
  </div>
</template>

<script>
import PackageBox from "@/components/Packages/PackageBox";

export default {
  components: {PackageBox},
  data() {
    return {
      packages: null,
      pagination: {
        page: 1,
        total: 1
      }
    }
  },
  props: {
    type: String,
  },
  methods: {
    getPackages() {
      window.scrollTo({top: 0})
      this.$emitEvent('setLoading', true)
      this.requesting('packages', 'getAllPackages', {page: this.pagination.page}, {
        type: this.type
      }).then((resp) => {
        this.toTop()
        this.packages = resp.data.packages
        this.pagination = {
          total: resp.data.pagination.total_pages,
          page: resp.data.pagination.current_page
        }
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    }
  },
  mounted() {
    this.getPackages()
  },
  watch: {
    'pagination.page'() {
      this.getPackages()
    }
  }
}
</script>

<style scoped>

</style>