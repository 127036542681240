<template>
  <div class="fill-height">

    <v-window v-model="sessionReserve" class="fill-height" touchless>
      <v-window-item value="wallet">
        <div class="emptyState" v-if="totalWallet.total == 0 && profile.wallet.drPackages.length == 0">
          <img src="../../assets/image/svg/session/emptyState.svg" alt="">
          <p>
            {{ $t('session_wallet.empty.title') }}
          </p>
          <v-btn color="#1c53c1" text @click="chargeWallet">
            {{ $t('session_wallet.empty.action') }}
          </v-btn>
        </div>
        <template v-else>
          <WalletSessionItemCard :session="totalWallet" @changeStep="changeStep($event)"/>
          <template v-for="(session , i) in profile.wallet.drPackages">
            <WalletSessionItemCard :session="session" :key="i" @changeStep="changeStep($event)"/>
          </template>
        </template>
      </v-window-item>
      <v-window-item value="reservingSession" class=" fill-height">
        <ReservingSession/>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
/*eslint-disable*/
import WalletSessionItemCard from "@/components/Sessions/WalletSessionItemCard";
import {mapGetters} from "vuex";
import ReservingSession from "@/components/Sessions/ReservingSession";

export default {
  components: {ReservingSession, WalletSessionItemCard},
  data() {
    return {
      sessionReserve: "wallet"
    }
  },
  methods: {
    chargeWallet() {
      this.$emit('changeStep', 'packages')
    },
    changeStep(val) {
      this.sessionReserve = val
    }
  },
  mounted() {
    this.requesting('auth', 'getProfile')
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile'
    }),
    totalWallet() {
      if (this.profile) {
        return {
          total: this.profile.wallet.global_packages,
          full_name: this.$t('session_wallet.general_session'),
          id: 'total_session'
        }
      }
      return {
        total: '',
        full_name: '',
        id: 'total_session'
      }
    }
  }
}
</script>

<style scoped>
.emptyState {
  text-align: center;
}

</style>