<template>
  <div class="package d-flex flex-column   mb-5">
    <div class="headerPackage d-flex justify-center align-center flex-column">
      <div class="price">
        <template v-if="$vuetify.rtl">
          {{ packageInfo.title }}
        </template>
        <template v-else>
          {{ packageInfo.en_title }}
        </template>
      </div>
    </div>
    <div class="bodyPackage flex-grow-1">
      {{ withCurrency(packageInfo.price, packageInfo.currency, 'long') }}
    </div>
    <div class="actionPackage text-center">
      <div class="perSessionLabel">
        {{ withCurrency(packageInfo.single_session_price, packageInfo.currency) }}
        {{ $t('packages.perSession') }}
      </div>
      <v-btn color="#3CBE8B" elevation="0" class="selectPackage" @click="addToCart">
        {{ $t('packages.buy') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import EventsMixins from "@/mixins/EventsMixins";

export default {
  mixins: [EventsMixins],
  props: {
    packageInfo: Object,
  },
  methods: {
    addToCart() {
      this.$emitEvent('setLoading', true)
      this.requesting('cart', 'addToCart', '', {
        package_id: this.packageInfo.id,
        addone: false
      }).then((item) => {

        let productInfo = item.data[0].items[this.packageInfo.id]
        this.WebengageAddToCart(productInfo);
        this.GTMAddToCart(productInfo)
        this.replaceCart(item.data[0])
        this.$router.push({name: 'basket'})
        this.$emitEvent('notify', {title: 'محصول مورد نظر شما اضافه شد', color: 'green'})
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    replaceCart(cart) {
      let payload = {
        key: 'cart',
        data: [
          cart
        ]
      }
      this.$store.dispatch('setCart', payload)
      // this.prepareBasketProduct()
    },
  },

}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .price {
    font-size: 12px !important;
    margin-bottom: 10px !important;
  }

  .bodyPackage {
    font-size: 15px !important;
    padding: 20px !important;
  }

  .perSessionLabel {
    font-size: 10px !important;
  }

  .package {
    min-height: 95% !important;
    height: auto !important;
  }

  .headerPackage {
    height: auto !important;
  }

  .selectPackage {
    width: 100% !important;
    height: 30px !important;
    font-size: 12px !important;
  }
}

.headerPackage {
  height: 110px;
  border-bottom: 2px solid #4597DF;
}

.package {
  overflow: hidden;
  height: 350px;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
}

.price {
  font-size: 20px;
  text-align: center;
  /*color: white;*/
}

.bodyPackage {
  padding: 40px;
  margin-bottom: 10px;
  font-size: 25px;
  text-align: center;
  line-height: 20pt;
  max-height: 140px;
  color: #4597DF;
}

.selectPackage {
  width: 192px;
  height: 48px;
  padding: 10px;
  border-radius: 15px;
  background-color: #46b1a1;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.perSessionLabel {
  color: #959595;
  margin-bottom: 5px;
}
</style>