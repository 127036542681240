<template>
  <div>
    <v-dialog v-model="cyclesModal.visibility" width="350px">
      <v-card>
        <v-card-title class="justify-center flex-column">
          <div class="titleModal">
            {{ $t('select_cycle.modal.modalTitle') }}
          </div>
          <div class="SubTitleModal">
            <template v-if="cycleWindow == 'cycles'">
              {{ $t('select_cycle.modal.description') }}
            </template>
            <template v-else>
              {{ $t('select_cycle.modal.select_title') }}
            </template>
          </div>
        </v-card-title>
        <div class="modalContent">
          <img src="../../assets/Cycle/gradiant.svg" style="position: absolute;right: 0px;left: 0px;width: 100%;"
               alt="">

          <div class="pt-5 px-8" style="z-index: 9999;position: relative">
            <v-window v-model="cycleWindow" reverse touchless>
              <v-window-item value="cycles">
                <div class="cycleBox mb-5  centering" v-for="(cycle ,i) in profile.cycles" :key="i"
                     @click="selectCycle(cycle)">
                  <div class="" style="cursor: pointer">
                    <div class="titleCycle" :class="!$vuetify.rtl && 'text-left'">
                      {{ cycle.heading }}
                    </div>
                    <div class="descCycle" :class="!$vuetify.rtl && 'text-left'">
                      {{ cycle.problem_description }}
                    </div>
                  </div>
                </div>
              </v-window-item>
              <v-window-item value="create">
                <v-row class="ma-0 pa-0">
                  <v-col class="pa-2" cols="12" lg="6" v-for="(category , i ) in cycleCategories" :key="i">
                    <div class="cycleBox centering" @click="selectCycleCategory(category)"
                         :class="selectedCategory == category.title && 'activeCycle'">
                      <div class="titleCycle" :class="!$vuetify.rtl && 'text-left'">
                        {{ category.title }}
                      </div>
                      <div class="descCycle" :class="!$vuetify.rtl && 'text-left'">
                        {{ category.desc }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item value="description">
                <div class="descriptionTextArea">
                  <textarea v-model="cycleDescription" rows="10" col="12" :placeholder="$t('description')"></textarea>
                </div>
              </v-window-item>
            </v-window>
          </div>
        </div>
        <v-card-actions class="actionArea px-8 pt-5">
          <v-fade-transition mode="out-in">
            <div class="d-flex flex-column" style="width: 100%" v-if="cycleWindow == 'cycles'" :key="1">
              <div>
                <v-btn color="#ecf7f5" class="createCycleBtn" elevation="0" block @click="cycleWindow = 'create'">
                  {{ $t('select_cycle.modal.add_cycle') }}
                </v-btn>
                <v-btn text color="" class="createCycleBtn" elevation="0" block @click="closeModal">
                  {{ $t('return') }}
                </v-btn>
              </div>
            </div>
            <div v-else :key="2" class="d-flex" style="width: 100%">
              <div class="flex-grow-1">
                <v-btn class="createCycleBtn" block elevation="0" color="#ecf7f5" @click="cycleWindow = 'description'"
                       v-if="cycleWindow == 'create'">
                  {{ $t('select_cycle.modal.add_description') }}
                </v-btn>
                <v-btn class="createCycleBtn" block elevation="0"
                       color="#ecf7f5" @click="createCycle"
                       v-else>
                  {{ $t('confirm') }}
                </v-btn>

              </div>
              <div>
                <v-btn text color="#46b1a1" class="px-10 createCycleBtn" elevation="0" @click="cycleWindow='cycles'">
                  {{ $t('return') }}
                </v-btn>
              </div>
              <!--            <v-btn color="#ecf7f5" class="createCycleBtn" elevation="0" @click="cycleWindow = 'create'">-->
              <!--              افزودن توضیحات-->
              <!--            </v-btn>-->
              <!--            <v-btn text color="#46b1a" class="" elevation="0" block @click="closeModal">-->
              <!--              بازگشت-->
              <!--            </v-btn>-->
            </div>
          </v-fade-transition>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    cyclesModal: Object
  },
  computed: {
    ...mapGetters({
      profile: 'getUserInfo'
    })
  },
  data() {
    return {
      cycleWindow: 'cycles',
      selectedCategory: null,
      cycleCategories: [
        {
          title: this.$t('select_cycle.cycle_heading.Individual.title'),
          desc: this.$t('select_cycle.cycle_heading.Individual.description')
        },
        {
          title: this.$t('select_cycle.cycle_heading.Couple.title'),
          desc: this.$t('select_cycle.cycle_heading.Couple.description')
        },
        {
          title: this.$t('select_cycle.cycle_heading.family.title'),
          desc: this.$t('select_cycle.cycle_heading.family.description')
        },
        {
          title: this.$t('select_cycle.cycle_heading.kid.title'),
          desc: this.$t('select_cycle.cycle_heading.kid.description')
        },
        {
          title: this.$t('select_cycle.cycle_heading.sex.title'),
          desc: this.$t('select_cycle.cycle_heading.sex.description')
        },
        {
          title: this.$t('select_cycle.cycle_heading.Psychiatry.title'),
          desc: this.$t('select_cycle.cycle_heading.Psychiatry.description')
        },
      ],
      cycleDescription: null,
    }
  },
  methods: {
    selectCycle(cycle) {
      this.$emit('selectCycle', cycle)
    },
    closeModal() {
      this.$emit('closeModal')
    },
    createCycle() {
      let payload = {
        title: this.selectedCategory,
        description: this.cycleDescription
      }
      this.$emitEvent('setLoading', true)
      this.requesting('user', 'insertCycle', '', payload).then(() => {
        this.requesting('auth', 'getProfile').then(() => {
          this.$emitEvent('notify', {title: this.$t('select_cycle.notify.addit'), color: 'green'})
          this.cycleWindow = 'cycles'
        })
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    selectCycleCategory(category) {
      this.selectedCategory = category.title
      this.cycleWindow = 'description'
    }
  }
}
</script>

<style scoped>
.gradiantImg {
  width: 100%;
}

.modalContent {
  /*margin-top: -40px;*/
  min-height: 350px;
  max-height: 355px;
  overflow-x: auto;
  /*background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 27%, rgba(28, 186, 136, 0.11));*/
}

.titleModal {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0, 0, 0);
}

.SubTitleModal {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: rgb(109, 109, 109);
}

.cycleBox {
  height: 70px;
  border-radius: 10px;
  overflow: hidden;
  border: solid 1px rgb(109, 109, 109);
  padding: 0px 5px;
  color: #6d6d6d;
  transition: all 0.3s;
}

.activeCycle {
  border: 1px solid #46b1a1;
  color: #46b1a1 !important;
  transition: all 0.3s;
}

.descCycle {
  height: 22px;
  overflow: hidden;
  font-size: 11px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
}

.titleCycle {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
}

.createCycleBtn {
  color: #46b1a1;
  height: 45px !important;
  border-radius: 15px;
}

.categoryBox {
  height: 62px;
  border-radius: 10px;
  padding-right: 5px;
  border: solid 1px rgb(109, 109, 109);
}

.descriptionTextArea {
  border-bottom: 1px solid #dbdbdb;
}

.descriptionTextArea textarea {
  width: 100%;
}
</style>