<template>
  <div>
    <div class="walletSession">
      <div class="walletSessionItem">
        <div class="headerWalletSessionItem">
          <span class="ContWallet">
          {{ session.total }}
          </span>
          {{ $t('session_wallet.therapy_session') }}
        </div>
        <div class="footerWalletSessionItem d-flex justify-space-between">
          <div>
            {{ session.full_name }}
          </div>
          <div>
            <v-btn
                :disabled="session.total == 0"
                color="#1c53c1"
                elevation="0"
                :id="`${session.id}_btn`"
                class="reverseBtn"
                @click="selectedSessionWallet(session)">
              {{ $t('session_wallet.action') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    session: Object,
  },
  methods: {
    selectedSessionWallet(session) {
      this.$store.commit('SET_SELECTED_SESSION_WALLET', session)
      this.$emit('changeStep', 'reservingSession')
    }
  }
}
</script>

<style scoped>
.walletSession {
  margin: 25px 0px;
}

.walletSessionItem {
  height: 170px;
  background: url("../../assets/image/png/session/BG.png");
  background-size: cover;
  border-radius: 15px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #1c53c1;
}

.headerWalletSessionItem {
  font-size: 21px;
  font-weight: bolder;
}

.reverseBtn {
  width: 156px;
  height: 35px;
  color: white;
  border-radius: 10px;
}
</style>