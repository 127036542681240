<template>
  <div class="indicatorArea mt-2 d-flex">
<!--    <div @click="nextStep">-->
<!--      <img src="../../assets/image/svg/session/Next.svg" alt="">-->
<!--    </div>-->
    <div class="flex-grow-1 mx-4">
      <div class="indicator d-flex flex-row-reverse">
        <div class="activeIndicatorStep " :style="{width: step * 33 + '%'}"></div>
      </div>
      <div class="indicatorLabels mt-1 d-flex flex-row-reverse">
        <div class="indicatorLabel activeIndicatorLabel flex-grow-1 text-center">
          {{$t('reserve_session.segment_control.title')}}
        </div>
        <div class="indicatorLabel flex-grow-1 text-center" :class="step > 1 && 'activeIndicatorLabel'">
          {{$t('reserve_session.segment_control.time')}}
        </div>
        <div class="indicatorLabel flex-grow-1 text-center" :class="step > 2 && 'activeIndicatorLabel'">
          {{$t('reserve_session.segment_control.reserve')}}
        </div>
      </div>
    </div>
<!--    <div>-->
<!--      <img src="../../assets/image/svg/session/Back.svg" alt="">-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    step: String,
  },
  methods:{
    nextStep(){

    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .indicatorArea {
    width: 80% !important;
  }
}

.indicatorArea {
  width: 55%;
  margin: 0px auto;
}

.indicator {
  background: #f2f5fe;
  border-radius: 10px;
  height: 20px;
  padding: 3px;
}

.activeIndicatorStep {
  border-radius: 10px;
  background-color: #1c53c1;
  transition: all 0.3s;
}

.indicatorLabel {
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
}

.activeIndicatorLabel {
  color: #1c53c1;
  font-weight: bolder;
}
</style>